<template>
  <div>
    <p class="title"><i class="el-icon-tickets"></i>{{title}}</p>
    <el-table
      :data="tableData"
      border
      style="width: 100%;padding-bottom: 3px">
      <el-table-column
        type="index">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableCols"
        :key="index"
        :sortable="item.isSort || false"
        :label="item.label"
        :width="item.width || (item.type && 130)">
        <template slot-scope="scope" >
          <div style="display: flex;justify-content: space-evenly" v-if="item.type">
            <el-button
              v-if="item.type === 'password' && scope.row['role'] === '管理员'"
              size="mini"
              type="primary"
              plain
              @click="handleLook(item.type,scope.row, item.prop)">初始化</el-button>
            <template v-if="item.type !== 'password'">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="handleLook(item.type,scope.row, item.prop)">查看</el-button>
            </template>
          </div>
          <div v-else>
            <el-select v-if="item.isSelect && scope.row['role'] !== '管理员'" v-model="scope.row[item.prop]">
              <el-option v-for="option in item.optionContent" :key="option" :label="option" :value="option"></el-option>
            </el-select>
            <el-tooltip v-else style="margin: 4px;" effect="dark" :content="scope.row[item.prop]" placement="top-start">
                <div class="text-over">{{scope.row[item.prop]}}</div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="handleCols">
        <template slot-scope="scope">
          <el-button
            v-for="(item, index) in handleCols"
            :key="index"
            :size="item.size"
            :type="item.type"
            @click="item.click(scope.$index, scope.row)">{{item.label}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-else :width="width">
       <template slot-scope="scope">
          <el-button
            v-if="onEdit && !scope.row['role']"
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-button
            v-if="scope.row['role'] && scope.row['role'] !== '管理员'"
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)">保存</el-button>
          <el-button
            v-if="onDelete && scope.row['role'] !== '管理员'"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <template v-if="!hiddenMove">
              <el-button
                :disabled="scope.$index === 0 && currentPage === 1"
                size="mini"
                @click="handleUp(scope.$index, scope.row)">↑</el-button>
              <el-button
                size="mini"
                @click="handleDown(scope.$index, scope.row)">↓</el-button>
            </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!hiddenPagination"
      class='pagination'
      background
      @size-change="onSizeChange"
      @current-change="onCurrentPageChange"
      :current-page="currentPage"
      :page-sizes="[10, 15, 20, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <div v-if="isOpenEditor" class="editor-content">
      <!-- 模拟Editor的样式 只进行展示 -->
      <el-button
        size="mini"
        type="primary"
        plain
        @click="handleReturn">返回</el-button>
      <div class="w-e-text-container">
        <div class="w-e-text">
          <div class='showContent' v-html="editorValue"></div>
        </div>
      </div>
    </div>
    <div v-if="isOpenPic" class="editor-content pics-content">
      <el-button
        size="mini"
        type="primary"
        @click="() => {isOpenPic=false}">关闭</el-button>
      <div class="pics-box" >
        <img v-for="(pic, index) in pics" :key="index" :src="pic">
      </div>
    </div>
  </div>
</template>

<script>
import { initPassword } from '../../api/apis/users'
export default {
  name: 'Table',
  data () {
    return {
      editorValue: '',
      isOpenEditor: false,
      pics: [],
      picsList: [],
      files: [],
      isOpenPic: false
    }
  },
  computed: {
    width () {
      let width = 100
      if (this.onEdit) width += 50
      if (!this.hiddenMove) width += 100
      return width
    }
  },
  props: {
    tableCols: {
      type: Array,
      default: () => [],
      required: true
    },
    title: {
      type: String,
      default: '表格名称'
    },
    tableData: {
      type: Array,
      default: () => [],
      required: true
    },
    handleCols: {
      type: Array
    },
    onEdit: {
      type: Function
    },
    onDelete: {
      type: Function
    },
    onMove: {
      type: Function,
      default: () => () => {}
    },
    total: {
      type: Number
    },
    currentPage: {
      type: Number,
      default: 1
    },
    onSizeChange: {
      type: Function,
      default: () => () => {}
    },
    onCurrentPageChange: {
      type: Function,
      default: () => () => {}
    },
    hiddenPagination: {
      type: Boolean,
      default: false
    },
    hiddenMove: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    editorValue: {
      immediate: true,
      handler (val) {
        const reg = /<img[^>]+src=['"]([^'"]+)['"]+/g
        const result = []
        let temp = []
        while ((temp = reg.exec(val)) != null) {
          result.push(temp[1].split('/').slice(-2).join('/'))
        }
        this.files = result
      }
    }
  },
  methods: {
    handleUp (index, row) {
      this.onMove(index, row, false)
    },
    handleDown (index, row) {
      this.onMove(index, row, true)
    },
    handleEdit (index, row) {
      this.onEdit(row)
    },
    getIndex (id) {
      return this.tableData.findIndex(x => x.id === id)
    },
    handleReturn () {
      this.isOpenEditor = false
    },
    handleLook (type, row, prop) {
      const data = row[prop]
      if (type === 'img') {
        this.pics = data?.map(x => x.absPath) || []
        this.files = data?.map(x => x.relativePath) || []
        this.picsList = data?.map(x => ({ name: x.relativePath.split('/')[1], url: x.absPath, relativeUrl: x.relativePath })) || []
        this.isOpenPic = true
      }
      if (type === 'content') {
        this.editorValue = data || ''
        this.isOpenEditor = true
      }
      if (type === 'password') {
        this.$confirm('此操作将会初始化密码为：Ab-gout1234,是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // 初始化密码
          const { status } = await initPassword(row.id)
          if (status === 'ok') {
            this.$message.success('密码初始化成功！')
            if (this.$store.state.username === row.username) {
              this.$store.dispatch('clearSession')
              this.$message.success('请重新登录')
              this.$router.push({ name: 'login' })
            }
          } else {
            this.$message.success('密码初始化失败！')
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消密码初始化'
          })
        })
      }
    },
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.onDelete(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="scss">
  $top:top;
  $bottom:bottom;
  $left:left;
  $right:right;
  $leftright: ($left, $right);
  $pinkk: #eec2d3;
  $bluee: #409eff;
  $yelloww: #f4d884;
  $grennn: #89dda0;
  $purplee: #78a2ea;
  $lightBluee: #b8d6ff;

  $list: bluee pinkk yelloww grennn purplee lightBluee;
  $list1: $bluee $pinkk $yelloww $grennn $purplee $lightBluee;
  %shadow{
    background: #fff;
    -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.2);
    .title{
      font-size: 14px;
      padding: 10px;
      i{
        margin-#{$right}: 5px;
      }
    }
  }

  @mixin flex($direction:row,$content:space-between){
    display: flex;
    flex-direction: $direction;
    justify-content: $content;
  }
  .editor-content{
    position: fixed;
    width: calc(100% - 220px);
    left: 200px;
    top: 50px;
    // transform: translate(-50%, -50%);
    margin: 0 10px;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #36a9aa;
    .btns-box {
      padding: 2px 7px;
      border-bottom: 1px solid #aaa;
    }
    .showContent {
      height: 80vh;
      overflow-y: auto;
    }
  }
  .pics-content {
    display: flex;
    top: 60px;
    width: calc(100% - 240px);
    // min-height: 200px;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    .pics-box {
      display: flex;
      height: 80vh;
      width: 100%;
      overflow-y: auto;
      justify-content: space-between;
      // align-items: center;
      flex-wrap: wrap;
      img {
        height: 180px;
        margin: 5px 2px;
      }
    }
  }
  .card{
    color: #666;
    @extend %shadow;
    ul{
      // @include flex;
      li{
        flex: 1;
        a{
          color: #666666;
          align-items:center;
          padding-#{$top}: 20px;
          padding-#{$bottom}: 20px;
          @include flex(column);
          span{
            height: 44px;
          }
          .num{
            line-height: 44px;
            font-size: 42px;
            color: $bluee;
            margin: 0px;
          }
        }
        .kjfs-bluee{
          color: $bluee;
        }
        .kjfs-pinkk{
          color: $pinkk;
        }
        .kjfs-yelloww{
          color: $yelloww;
        }
        .kjfs-grennn{
          color: $grennn;
        }
        .kjfs-purplee{
          color: $purplee;
        }
        .kjfs-lightBluee{
          color: $lightBluee;
        }
        &:hover{
          .kjfs-bluee{
            color: #ffffff;
            background: $bluee;
          }
          .kjfs-pinkk{
            color: #ffffff;
            background: $pinkk;
          }
          .kjfs-yelloww{
            color: #ffffff;
            background: $yelloww;
          }
          .kjfs-grennn{
            color: #ffffff;
            background: $grennn;
          }
          .kjfs-purplee{
            color: #ffffff;
            background: $purplee;
          }
          .kjfs-lightBluee{
            color: #ffffff;
            background: $lightBluee;
          }
        }
      }
    }
    .table{
      padding: 21px;
      p{
        height: 52px;
        line-height: 52px;
        border: 1px solid #cccccc;
        overflow: hidden;
        border-#{$top}: none;
        @include flex( null,flex-start);
        &:first-child{
          border-#{$top}: 1px solid #cccccc;
        }
        .tit{
          width: 90px;
          min-width: 90px;
          height: 100%;
          text-align: center;
          border-#{$right}: 1px solid #cccccc;
          margin-#{$right}: 18px;
        }
        span.gitbox{
          flex: 1;
          height: 100%;
          overflow: hidden;
          @include flex(row,flex-start);
          a{
            &:first-child{
              margin-#{$right}: 30px;
            }
          }
        }
      }
    }
  }
  .table-wrapper {
    margin-#{$top}: 10px;
    padding-#{$top}: 10px;
    @extend %shadow;
  }
  p.dragtabe{
    margin-top: 30px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
</style>
